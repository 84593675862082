import { getFechaObj } from "./commonUtils"

export const getUserTokenCallback = async (user, forceRefresh = false, callback) => {
  if (!user) return
  const token = await user.getIdToken(forceRefresh)

  if (callback) {
    const output = await Promise.resolve(callback(token))
    return { output, token }
  }

  return token
}

export const getUserInfoFromTokenBase = async (firebase, token) =>
  await firebase.auth().verifyIdToken(token)

export const getUserFromEmailBase = async (firebase, email) =>
  await firebase.auth().getUserByEmail(email)

export const getUserBase = async (firebase, uid) =>
  await firebase.auth().getUser(uid)

export const getSessionCookieBase = async (firebase, token, { expiresIn }) =>
  await firebase.auth().createSessionCookie(token, {
    expiresIn,
  })

export const checkSessionCookieBase = async (firebase, sessionCookie, checkRevoked = false) =>
  await firebase.auth().verifySessionCookie(sessionCookie, checkRevoked)


const defaultOptions = { rawData : false, includeKey : false, logItem : true, basicLogs : true }

export const getItemsAllEntityBase = async (
  firebase,
  siteId,
  entity,
  area,
  options = {}
) => {
  const { rawData, includeKey, logItem, basicLogs } = {
    ...defaultOptions,
    ...options,
  }
  // console.debug("🚀 ~ file: firebaseBaseDataUtils.js:42 ~ options:", options , { rawData , includeKey, logItem, basicLogs })

  if (!siteId || !entity || !area) return

  const refPath = `/app/${siteId}/${area}/${entity}`
  if(logItem || basicLogs) console.debug("🚀 ~ file: firebaseBaseDataUtils.js:48 ~ getItemsAllEntityBase ~ refPath:", refPath)
  const dbRef = firebase.database().ref(refPath)

  const snapshot = await dbRef.once("value")
  // const val = snapshot.val()

  // if (rawData){
  //   if(logItem) console.debug("getItemsAllEntityBase ~ val:", val)
  //   return val
  // }

  // const values = val ? Object.values(val) : val

  // if(logItem) console.debug("getItemsAllEntityBase ~ values:", {"values?.length":values?.length, "values?.[0]":values?.[0]})
  // else if(basicLogs) console.debug("getItemsAllEntityBase ~ values?.length:", values?.length)

  // return val && includeKey
  //   ? Object.entries(val).map(([key, val]) => ({ ...val, key }))
  //   : values

  return getItemsAllFromSnapshot(snapshot, { ...defaultOptions, ...options })
}

export const getItemsAllFromSnapshot = (snapshot, options, defaultVal) => {
  const { rawData , includeKey, logItem, basicLogs } = options
  const val = snapshot.val()

  if (rawData){
    if(logItem) console.debug("getItemsAllFromSnapshot ~ val:", val)
    return val
  }

  const values = val ? Object.values(val) : defaultVal ?? val

  if(logItem) console.debug("getItemsAllFromSnapshot ~ values:", {"values?.length":values?.length, "values?.[0]":values?.[0]})
  else if(basicLogs) console.debug("getItemsAllFromSnapshot ~ values?.length:", values?.length)

  return val && includeKey
    ? Object.entries(val).map(([key, val]) => ({ ...val, key }))
    : values
}

export const getItemByParamFromSnapshot = (snapshot, options, defaultVal) => {
  const { includeKey, logItem, basicLogs } = options

  const val = snapshot.val()
  if (logItem) console.debug("getItemByParamFromSnapshot ~ val:", val)
  const entry = val ? Object.entries(val)[0] : val
  return entry && includeKey ? { ...entry[1], key: entry[0] } : entry?.[1] ??  defaultVal

}

export const getDataByFullPathBase = async (firebase, fullPath) => {
  console.debug("🚀 ~ file: firebaseBaseDataUtils.js:45 ~ getDataByFullPathBase ~ fullPath:", fullPath)
  if (!fullPath) return

  const refBase = fullPath

  const dbRef = firebase.database().ref(refBase)

  const snapshot = await dbRef.once("value")
  const val = snapshot.val()

  return val

}

export const getItemEntityBase = async (
  firebase,
  siteId,
  entity,
  area,
  key,
  options = {}
) => {
  const { rawData, includeKey, logItem, basicLogs } = {
    ...defaultOptions,
    ...options,
  }

  if (!siteId || !entity || !area || !key) return

  const refPath = `/app/${siteId}/${area}/${entity}/${key}`
  const dbRef = firebase.database().ref(refPath)
  if(logItem || basicLogs) console.debug("🚀 ~ file: firebaseBaseDataUtils.js:93 ~ getItemEntityBase ~ refPath:", refPath)
  
  const snapshot = await dbRef.once("value")
  // const val = snapshot.val()
  
  // if (logItem) console.debug("getItemEntityBase ~ val:", val)
  // return val && includeKey ? { ...val, key } : val
  return getItemFromSnapshot(key, snapshot, { ...defaultOptions, ...options })
}

export const getItemFromSnapshot = (key, snapshot, options, defaultVal) => {
  const { includeKey, logItem, basicLogs } = options
  const val = snapshot.val()
  if (logItem) console.debug("getItemFromSnapshot ~ val:", val)
  return val && includeKey ? { ...val, key } : val ?? defaultVal
}

export const deleteItemEntityBase = async (
  firebase,
  siteId,
  entity,
  area,
  key,
  options = {}
) => {
  const { logItem = true, basicLogs = true } = options

  if (!siteId || !entity || !area || !key) return false


  const refPath = `/app/${siteId}/${area}/${entity}/${key}`
  if(logItem || basicLogs) console.debug("🚀 ~ file: firebaseBaseDataUtils.js:116 ~ deleteItemEntityBase ~ refPath:", refPath)
  const dbRef = firebase.database().ref(refPath)

  await dbRef.remove()

  if(logItem) console.debug("deleteItemEntityBase ~ key:", key)
  return true
}

export const updateItemEntityBase = async (
  firebase,
  site,
  entity,
  area,
  key,
  datos,
  options = {}
) => {
  const { includeKey = false, getItem = false, logItem = true, basicLogs=true } = options

  if (!site || !entity || !area || !key || !datos) return

  const refPath = `/app/${site}/${area}/${entity}/${key}`
  if(logItem || basicLogs) console.debug("🚀 ~ file: firebaseBaseDataUtils.js:141 ~ updateItemEntityBase ~ refPath:", refPath)
  const dbRef = firebase.database().ref(refPath)

  await dbRef.update(datos)

  if(logItem) console.debug("updateItemEntityBase ~ datos:", datos)
  if (getItem) return await getItemEntityBase(firebase, site, entity, area, key, options)

  return includeKey ? { ...datos, key } : datos
}

export const addItemEntityBase = async (
  firebase,
  site,
  entity,
  area,
  key,
  datos,
  options = {}
) => {
  const { includeKey = false, getItem = false, logItem = true, basicLogs=true } = options

  if (!site || !entity || !area || !datos) {
    if(logItem) console.error("addItemEntityBase:", {site, entity, area, datos})
    return
  }

  const tieneKey = !!key
  let keyNew = key

  const refPath = tieneKey
  ? `/app/${site}/${area}/${entity}/${key}`
  : `/app/${site}/${area}/${entity}/`
  
  if(logItem || basicLogs) console.debug("🚀 ~ file: firebaseBaseDataUtils.js:170 ~ addItemEntityBase ~ refPath:", refPath)
  

  const dbRef = firebase.database().ref(refPath)

  if (tieneKey) {
    await dbRef.set(datos)
  } else {
    const data = await dbRef.push(datos)
    keyNew = data.getKey()
  }
  
  if (logItem) console.debug("addItemEntityBase ~ datos:", keyNew, datos)

  if (getItem) return await getItemEntityBase(firebase, site, entity, area, keyNew, options)

  return includeKey ? { ...datos, key: keyNew } : datos
}


export const seleccionarEliminarEntityBase = async (firebase, siteId, entity, area, key, options = {}) => {

  const options_ = {
    ...defaultOptions,
    ...options,
  }
  const {fechaField = "fechaActualizacion"} = options_

  const item = await getItemEntityBase(firebase, siteId, entity, area, key, options_)

  if (!item)
    throw new Error(`No existe un item con key [${key}]`)

  const updData = {
    DELETE: "DELETE",
    ...getFechaObj(fechaField),
  }

  return await updateItemEntityBase(
    firebase,
    siteId,
    entity,
    area,
    key,
    updData,
    options_
  )
}


export const cancelarEliminarEntityBase = async (firebase, siteId, entity, area, key, options = {}) => {

  const options_ = {
    ...defaultOptions,
    ...options,
  }
  // const {fechaField = "fechaActualizacion"} = options_
  
  const item = await getItemEntityBase(firebase, siteId, entity, area, key, options_)

  if (!item)
  throw new Error(`No existe un item con key [${key}]`)

  return await deleteItemEntityBase(
    firebase,
    siteId,
    entity,
    area,
    key + "/DELETE",
    options_
  )
}

export const confirmarEliminarEntityBase = async (
  firebase,
  siteId,
  entity,
  area,
  key,
  options = {}
) => {
  
  const options_ = {
    ...defaultOptions,
    ...options,
  }

  const item = await getItemEntityBase(
    firebase,
    siteId,
    entity,
    area,
    key,
    options_
  )

  if (!item) throw new Error(`No existe un item con key [${key}]`)

  if (item.DELETE != "DELETE")
    throw new Error(`No se puede eliminar el item [${key}]`)

  console.debug("🚀 ~ file: firebaseBaseDataUtils.js:325 ~ item:", item)



  return await deleteItemEntityBase(
    firebase,
    siteId,
    entity,
    area,
    key,
    options_
  )
  
}